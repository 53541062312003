@import url(https://fonts.googleapis.com/css2?family=Spline+Sans:wght@400;600;700&display=swap);
html,
body,
#root {
  margin: 0;
  height: 100%;
  font-family: "Spline Sans", sans-serif;
}

main {
  height: 100%;
  background: #afade2;
}

*::selection {
  background: transparent;
}

:root {
  --color-title: #fefeff;
  --color-cta-bg: rgba(56, 63, 126, 0.6);
  --color-cta-text: #b2afe4;
  --color-highlight: #383f7e;
  --color-text-main: rgba(56, 63, 126, 0.6);
}

.Overlay_container__33jn_ {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.Overlay_container__33jn_ header {
  position: absolute;
  top: 70px;
  left: 62px;
}

.Overlay_container__33jn_ header h1 {
  font-weight: 600;
  font-size: 44px;
  margin: 11px 0;
  color: var(--color-title);
}

.Overlay_container__33jn_ strong {
  color: var(--color-highlight);
}

.Overlay_container__33jn_ header sup {
  display: inline-block;
  color: var(--color-cta-text);
  background: var(--color-cta-bg);
  border-radius: 4px;
  font-size: 14px;
  line-height: 151.5%;
  padding: 1px 9px;
  position: relative;
  top: -29px;
  left: -25px;
}

.Overlay_container__33jn_ nav {
  position: absolute;
  left: 62px;
  top: 350px;
	font-size: 28px;
	line-height: 38px;
}
  
.Overlay_container__33jn_ section {
  font-size: 28px;
  line-height: 38px;
}

.Overlay_container__33jn_ nav a {
  display: block;
  color: var(--color-text-main);
}

.Overlay_selected__cHt12 {
  color: var(--color-highlight) !important;
}

.Overlay_container__33jn_ section {
  width: 400px;
  position: absolute;
  top: 186px;
  left: 62px;
  color: var(--color-text-main);
}

.Overlay_container__33jn_ a {
  text-decoration: none;
  color: var(--color-highlight);
}

.Overlay_container__33jn_ a:hover {
  text-decoration: underline;
  color: var(--color-highlight);
}

.Overlay_container__33jn_ section strong {
  font-weight: 400;
}

.Overlay_container__33jn_ footer {
  position: absolute;
  bottom: 60px;
  left: 62px;
  color: var(--color-text-main);
  font-size: 16px;
  line-height: 19px;
}

@media only screen and (max-width: 768px) {
  .Overlay_container__33jn_ header {
    top: 38px;
    left: 38px;
  }

  .Overlay_container__33jn_ header h1 {
    font-size: 48px;
  }

  .Overlay_container__33jn_ header sup {
    font-size: 12px;
    padding: 3px 10px;
    top: -24px;
    left: -24px;
  }

  .Overlay_container__33jn_ nav,
  .Overlay_container__33jn_ section {
    font-size: 24px;
    line-height: 28px;
  }

  .Overlay_container__33jn_ section {
    width: 298px;
    left: 38px;
  }

  .Overlay_container__33jn_ footer {
    left: 38px;
  }
}

@media only screen and (max-width: 480px) {
  .Overlay_container__33jn_ header {
    top: 20px;
    left: 20px;
  }

  .Overlay_container__33jn_ header h1 {
    font-size: 36px;
  }

  .Overlay_container__33jn_ header sup {
    font-size: 11px;
    padding: 2px 8px;
    top: -20px;
    left: -18px;
  }

  .Overlay_container__33jn_ nav,
  .Overlay_container__33jn_ section {
    font-size: 20px;
    line-height: 23px;
  }

  .Overlay_container__33jn_ section {
    width: 228px;
    left: 20px;
    bottom: 70px;
  }

  .Overlay_container__33jn_ footer {
    left: 20px;
    bottom: 20px;
    font-size: 13px;
  }
}

